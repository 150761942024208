<template>
	<section id="intro" class="main-content intro">
		<div class="row">
			<slot name="content">
				<div class="columns column6">
					<h1>{{ title }}</h1>
				</div>
				<div class="columns column6">
					<div v-parse-links v-html="content" />
					<p v-if="bookingid">
						<a
							href="#"
							target="_blank"
							rel="noopener noreferrer"
							:bookingid="bookingid"
							class="button button-cta"
						>
							{{ $t('bookThisRoom') }}
						</a>
					</p>
				</div>
			</slot>
			<slot />
		</div>
	</section>
</template>

<script>
export default {
	props: {
		title: { type: String, default: '' },
		content: { type: String, default: '' },
		bookingid: { type: String, default: '' },
	},
};
</script>
